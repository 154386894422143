import topic from '../topic';
import { getNoCatch, postNoCatch } from '@api/request';
export const usquAPI = {
  get_usqus: params => getNoCatch(`${topic.usquTopic}/get_usqus`, params),
  get_usqus_by_id: params => getNoCatch(`${topic.usquTopic}/get_usqus_by_id`, params),
  add_usqus: params => postNoCatch(`${topic.usquTopic}/add_usqus`, params),
  edit_usqus: params => postNoCatch(`${topic.usquTopic}/edit_usqus`, params),
  deltet_by_uaqu_ids: params => postNoCatch(`${topic.usquTopic}/deltet_by_uaqu_ids`, params),
  getFcss: params => getNoCatch(`${topic.usquTopic}/fcss_list`, params)
};

import { getDateNoTime } from '@assets/js/dateUtils';
import store from '@/store';
export const tableProperties = [
  store.state.index,
  { label: '面料名称', prop: 'mtrl_name', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '英文名称', prop: 'mtrl_ename', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '潘通色号', prop: 'mtrl_color', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '用量', prop: 'smpl_mtrl_consume', itemType: 'input', input: true, sortable: true, widthAuto: true },
  {
    label: '更新日期',
    prop: 'fcss_time',
    itemType: 'date',
    input: true,
    sortable: true,
    widthAuto: true,
    formatter: val => getDateNoTime(val, false)
  }
];

<template>
  <div class="vg_wrapper">
    <el-card>
      <div class="vg_mb_8">
        <el-button type="info" size="small" class="vd_export vg_mr_8" icon="el-icon-refresh-right" @click="refresh()"> 刷新</el-button>
        <el-date-picker
          class="vg_mr_8 topDateRangeSearch"
          size="small"
          v-model="dateRange"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        />
        <el-button type="primary" size="small" icon="el-icon-search" @click="getTableData()"> 查询</el-button>
        <el-button type="success" size="small" icon="el-icon-upload2" @click="exportToExcel()"> 导出excel</el-button>
      </div>
      <search-table
        id="fcss_id"
        ref="multiTable"
        :data="tableData"
        :tableRowKey="'fcss_id'"
        :columns="tableProperties"
        v-loading="loadFlag"
        :totalPage="totalPage"
        @getTableData="getTableData"
      >
      </search-table>
    </el-card>
  </div>
</template>

<script>
import SearchTable from '@/components/table/SearchTableV2.vue';
import { tableProperties } from '@/views/SettleManagement/FcssManage/fcss';
import { usquAPI } from '@api/modules/usqu';
import htmlToExcel from '@/plugins/htmlToExcel';
import { cloneDeep } from 'lodash';

export default {
  name: 'RqutNewList',
  components: { SearchTable },
  data() {
    return {
      tableData: [],
      tableProperties: tableProperties,
      loadFlag: true,
      totalPage: 0,
      multiSelection: [],
      btn: {},
      dateRange: []
    };
  },
  mounted() {
    this.getTableData();
  },
  methods: {
    async getTableData() {
      await (this.loadFlag = true);
      let [startTime, endTime] = this.dateRange || [];
      let searchForm = cloneDeep(this.$refs.multiTable.searchForm);
      searchForm.startTime = startTime?.getTime();
      searchForm.endTime = endTime?.getTime();
      searchForm.fcss_time = searchForm.fcss_time ? searchForm.fcss_time.getTime() : null;
      await usquAPI.getFcss(searchForm).then(({ data }) => {
        this.tableData = data.list;
        this.totalPage = data.total;
        this.btn = data.btn;
      });
      await (this.loadFlag = false);
    },
    refresh() {
      this.dateRange = [];
      this.$refs.multiTable.resetFields();
      this.getTableData();
    },
    // 导出excel
    exportToExcel(val) {
      htmlToExcel.getExcel('#fcss_id', '面料统计');
    }
  }
};
</script>

<style scoped></style>
